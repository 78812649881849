.Toastify__toast {
  @apply mb-0 flex max-h-[40px] min-h-[40px] flex-row gap-3 rounded-full border-slate-300 bg-white p-2 px-3 text-gray-600 shadow-sm dark:border-gray-600 dark:bg-slate-700 dark:text-gray-100;
}

.Toastify__toast-body {
  @apply flex flex-row gap-3 p-0 text-sm font-semibold;
}

.Toastify__toast-icon {
  @apply m-0;
}

.Toastify__close-button {
  @apply my-auto;
}

.Toastify__toast-container {
  @apply top-1 flex h-[76px] items-center justify-center;
}

.Toastify__progress-bar {
  @apply h-full;
  @apply opacity-10 !important;
}
