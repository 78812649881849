@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Lisu+Bosa:wght@500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
}

#app-root.dark {
  height: 100vh;
  background-color: #0c0a09;
}

body {
  @apply overflow-x-hidden;
}

@layer components {
  .bg-checkered {
    background-image: linear-gradient(45deg, #57534e 25%, transparent 25%),
      linear-gradient(-45deg, #57534e 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #57534e 75%),
      linear-gradient(-45deg, transparent 75%, #57534e 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }

  .bg-white-checkered {
    background-image: linear-gradient(45deg, #e5e7eb 25%, transparent 25%),
      linear-gradient(-45deg, #e5e7eb 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #e5e7eb 75%),
      linear-gradient(-45deg, transparent 75%, #e5e7eb 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }

  .shadow-top-white-outline {
    box-shadow: 0 -1px 0 #fff;
  }
}
